import React from "react"
import styled from "styled-components"

import SEO, { Props as SEOProps } from "./seo"
import Header from "./header"
import Footer from "./footer"

const Main = styled.main`
  overflow: hidden;
  min-height: calc(100vh - 285px);
  @media (max-width: 500px) {
    min-height: calc(100vh - 190px);
  }
`

interface Props extends SEOProps {
  header?: boolean
  footer?: boolean
  home?: boolean
}

const Layout: React.FC<Props> = ({ children, home, ...props }) => (
  <>
    <SEO {...props} />
    {!home && <Header />}
    <Main>{children}</Main>
    <Footer />
  </>
)

export default Layout
