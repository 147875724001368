import styled from "styled-components"

interface Props {
  $center?: boolean
  $wide?: boolean
}

const Column = styled.div<Props>`
  width: calc(100% - 40px);
  margin: 0 auto 20px;
  max-width: ${({ $wide }) => ($wide ? 1000 : 600)}px;
  ${({ $center }) => ($center ? "text-align: center;" : "")}
`

export default Column
