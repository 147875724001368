import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import styled from "styled-components"

const Container = styled.header`
  padding: 20px 0;
  text-align: center;
  @media (min-width: 500px) {
    margin: 10px 0 20px;
  }
`
const Logo = styled(Img)`
  margin: 0 auto;
  width: 200px;
  display: inline-block;
  @media (max-width: 500px) {
    width: 120px;
  }
`

const Header: React.FC = () => {
  const { logo } = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo-small.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 1) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <Container>
      <Link to="/">
        <Logo fluid={logo.childImageSharp.fluid} alt="l’attrape miel - miel de la montagne limousine" />
      </Link>
    </Container>
  )
}

export default Header
