import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { COLORS, SIZES } from "src/helpers/constants"

const Container = styled.footer`
  width: calc(100% - 40px);
  max-width: 1000px;
  margin: 20px auto 0;
  padding: 5px 10px 7px;
  border-top: 1px solid ${COLORS.black};
  display: flex;
  justify-content: space-between;
`
const FooterLink = styled(Link)`
  text-decoration: none;
  font-size: ${SIZES.small}px;
  @media (max-width: 400px) {
    span {
      display: none;
    }
  }
`

const now = new Date()

const Footer: React.FC = () => {
  return (
    <Container>
      <FooterLink to="/">© l’attrape miel {now.getFullYear()}</FooterLink>
      <FooterLink to="/cgu/">
        mentions légales<span> et conditions de ventes</span>
      </FooterLink>
    </Container>
  )
}

export default Footer
